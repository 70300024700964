/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useCallback, useRef } from "react";
import TeasersCard from "components/LearnerPortal/TeasersCard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfiniteScroll from "react-infinite-scroll-component";

// reactstrap components
import { Row } from "reactstrap";
// core components
import { useDispatch, useSelector } from "react-redux";
import { logout } from "actions/auth";
import { ARTICLE_LENGTH } from "utilities/constant";
import { getDailyNews } from "actions/news";
import { getErrorMessage } from "utilities/error";
import { getTeasers } from "actions/news";



function Dashboard({onFocus}) {
	const newsState = useSelector((state) => state.news);
	const dispatch = useDispatch();
	const [isLastPage, setIsLastPage] = useState(false);
	const [page, setPage] = useState(0);
	const {user: userData, isHyperAdminLogin } = useSelector((state) => state.auth);
	

	useEffect(() => {
		fetchTeasers(page);
	}, []);

	const fetchTeasers =  async (page) => {
		try {
			let response = await dispatch(getTeasers(page, "teaser"));
			// if last getArticles
			if (response.data.length < ARTICLE_LENGTH) {
				setIsLastPage(true);
			}
			setPage(response.nextPage);
		} catch (e) {
			let error_message = getErrorMessage(e);
		
			toast.error(error_message);
		}
	};

	return (
		<>
			<div className="pb-6 pt-3 fadeInDiv">
				{newsState.isGetTeasers && 
					<div className='justify-content-center mt-4 w-100 flex'>
						<span className='spinner-border spinner-border-sm'></span>
					</div>}
				{newsState.teasers && newsState.teasers.length > 0 && (
					<InfiniteScroll
						className="pl-1 pr-1"

						style={{
					
						}}
						dataLength={newsState.teasers.length} //This is important field to render the next data
						next={() => {
							fetchTeasers(page);
						}}
						hasMore={!isLastPage}
						loader={<div className='justify-content-center mt-4 w-100 flex'>
							<span className='spinner-border spinner-border-sm'></span>
						</div>}
						endMessage={
							<p style={{ textAlign: "center" }}>
								{/* <b>Yay! You have seen it all</b> */}
							</p>
						}
						// below props only if you need pull down functionality
						// refreshFunction={(setItems) => null}
						pullDownToRefresh={false}
						// pullDownToRefreshThreshold={50}
						// pullDownToRefreshContent={
						// 	<h3 style={{ textAlign: "centser" }}># 8595; Pull down to refresh</h3>
						// }
						// releaseToRefreshContent={
						// 	<h3 style={{ textAlign: "center" }}># 8593; Release to refresh</h3>
						// }						
					>
						{
							newsState.teasers.map((single) => (
								<TeasersCard
									key={single.article_id}
									article={single}
									id={single.article_id}
									title={single.article_title}
									image_url={single.article_image_url}
									date={single.article_published}
									author={single.author.author_name}
									author_image_url={single.author.author_image_url}
								/>
							))
						}
						
						
					</InfiniteScroll>
				)}
		
				{!newsState.isGetTeasers && (!newsState.teasers || (newsState.teasers && newsState.teasers.length === 0)) && (
					<div className='justify-content-center mt-4 w-100 flex'>
						<p className='text-center flex'>
							<b>{"No Teaser"}</b>
						</p>
					</div>
				)}
			</div>
		</>
	);
}

// export default Dashboard;
export default React.memo(Dashboard);
