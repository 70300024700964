import classNames from "classnames"
import styles from "./EventGallery.module.scss"
import React, { useEffect } from "react"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import "photoswipe/style.css"

import "photoswipe/dist/photoswipe.css"

import "photoswipe/style.css"
import { useQuery } from "@tanstack/react-query"
import newsApi from "services/news-api"

export function EventGallery({ event_id }) {
	const query = useQuery({
		queryKey: ["eventPhotos", event_id],
		queryFn: async () => {
			const photos = await newsApi.getEventPhotos(event_id)
			return Promise.all(
				photos.data.map((photo) => {
					return new Promise((resolve, reject) => {
						const imageNode = new Image()
						imageNode.src = photo.url
						imageNode.onload = (event) => {
							resolve({
								width: event.target.width,
								height: event.target.height,
								...photo,
							})
						}
					})
				}),
			)
		},
	})

	const galleryID = "event-gallery-tests"
	useEffect(() => {
		if (query.data) {
			let lightbox = new PhotoSwipeLightbox({
				gallery: "#" + galleryID,
				children: "a",
				pswpModule: () => import("photoswipe"),
			})
			lightbox.init()

			return () => {
				lightbox.destroy()
				lightbox = null
			}
		}
	}, [query.data])

	return (
		<div>
			{query.data && query.data.length > 0 ? (
				<>
					<h3>Check out the Fun Photos from that Day</h3>
					<div className={classNames(styles.grid, "mt-4")} id={galleryID}>
						{query.data?.map((image, index) => {
							const sizeClassess = [styles.tall, styles.square, styles.square, styles.square]
							const classNameStr = classNames(styles.box, sizeClassess[index % sizeClassess.length])
							return (
								<a
									className={classNameStr}
									href={image.url}
									data-pswp-width={image.width}
									data-pswp-height={image.height}
									key={galleryID + "-" + index}>
									<img
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
											backgroundColor: "#efefef",
										}}
										src={image.url}
									/>
								</a>
							)
						})}
					</div>
				</>
			) : null}
		</div>
	)
}

export default function SimpleGallery(props) {
	useEffect(() => {
		let lightbox = new PhotoSwipeLightbox({
			gallery: "#" + props.galleryID,
			children: "a",
			pswpModule: () => import("photoswipe"),
		})
		lightbox.init()

		return () => {
			lightbox.destroy()
			lightbox = null
		}
	}, [])

	return (
		<div className='pswp-gallery' id={props.galleryID}>
			{props.images.map((image, index) => (
				<a
					href={image.largeURL}
					data-pswp-width={image.width}
					data-pswp-height={image.height}
					key={props.galleryID + "-" + index}
					target='_blank'
					rel='noreferrer'>
					<img src={image.thumbnailURL} alt='' />
				</a>
			))}
		</div>
	)
}
