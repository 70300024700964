/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import "react-toastify/dist/ReactToastify.css"

import { Badge, Button, Container } from "reactstrap"
import newsApi from "services/news-api"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import Chip from "@material-ui/core/Chip"
import EarthPlacdholder from "../../../../assets/img/Events/earth@4x.png"

import styles from "./EventHome.module.scss"
import Slider from "react-slick"
import {
	Checkbox,
	FormControlLabel,
	Paper,
	Popover,
	TablePagination,
	withStyles,
} from "@material-ui/core"
import { green } from "@material-ui/core/colors"
import Lottie from "react-lottie"
import classNames from "classnames"

const BlueCheckbox = withStyles({
	root: {
		color: green[400],
		"&$checked": {
			color: green[600],
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />)

function Dashboard() {
	const [searchState, setSearchState] = useState({
		keyword: null,
		category: null,
	})

	const [dateRangeSelection, setDateRangeSelection] = useState([])
	const [timeRangeSelection, setTimeRangeSelection] = useState([])
	const [categories, setCategories] = useState(new Set())
	const [page, setPage] = useState(0)
	const [searchParams, setSearchParams] = useSearchParams()

	const hasSearchFilter =
		Boolean(searchState.keyword) ||
		Boolean(Array.from(categories).length) ||
		Boolean(dateRangeSelection?.length) ||
		Boolean(timeRangeSelection?.length)

	const firstTime = useRef(true)

	/**
	 * Hyrdate the UI search state from the url
	 * Especially when they press the back button form the previous page
	 */
	useEffect(() => {
		if (firstTime.current) {
			firstTime.current = false
			searchParams.has("searchText") &&
				setSearchState({ ...searchState, keyword: searchParams.get("searchText") })
			searchParams.has("category") &&
				setCategories(
					new Set(
						searchParams
							.get("category")
							.split(",")
							.map((category) => parseInt(category)),
					),
				)
			searchParams.has("dateRange") &&
				setDateRangeSelection(searchParams.get("dateRange").split(","))

			searchParams.has("timeRange") &&
				setTimeRangeSelection(searchParams.get("timeRange").split(","))
		}
	}, [])

	/**
	 * Clear the search params when the search filter is cleared
	 */
	useEffect(() => {
		if (!hasSearchFilter && queries.search.isSuccess) {
			setSearchParams({})
		}
	}, [hasSearchFilter])

	/**
	 * When the search filter is changed, update the search params
	 */
	const serializedSearchInfo = {
		searchText: searchState.keyword || undefined,
		category: Array.from(categories).length ? Array.from(categories).join(",") : undefined,
		dateRange: dateRangeSelection ? dateRangeSelection.join(",") : undefined,
		timeRange: timeRangeSelection ? timeRangeSelection.join(",") : undefined,
	}
	useEffect(() => {
		if (hasSearchFilter) {
			const tempSearchParams = {}
			Object.keys(serializedSearchInfo).forEach((key) => {
				if (serializedSearchInfo[key]) {
					tempSearchParams[key] = serializedSearchInfo[key]
				}
			})

			setSearchParams(tempSearchParams)
		}
	}, Object.values(serializedSearchInfo))

	const queries = {
		categories: useQuery({
			queryKey: ["event-category"],
			staleTime: 60000,
			queryFn: async () => {
				const response = await newsApi.getEventCategories()
				return response.data
			},
			retry: false,
			placeholderData: [],
		}),
		events: useQuery({
			queryKey: ["featuredEvent"],
			staleTime: 60000,
			queryFn: async () => {
				const response = await newsApi.getFeaturedEvent()
				return response.data
			},
		}),
		search: useQuery({
			queryKey: [
				"event-search",
				searchState.keyword,
				Array.from(categories).length ? Array.from(categories).join(",") : undefined,
				dateRangeSelection ? dateRangeSelection.join(",") : undefined,
				timeRangeSelection ? timeRangeSelection.join(",") : undefined,
				page,
			],
			staleTime: 60000, // 1 minute , so that why press back button the search query wont' reexecute
			refetchInterval: false,
			enabled: hasSearchFilter,
			keepPreviousData: true,
			queryFn: async () => {
				let params = {
					searchText: searchState.keyword || undefined,
					category: Array.from(categories).length ? Array.from(categories).join(",") : undefined,
					dateRange: dateRangeSelection ? dateRangeSelection.join(",") : undefined,
					timeRange: timeRangeSelection ? timeRangeSelection.join(",") : undefined,
				}

				const response = await newsApi.getEvent({
					pageParam: page * 8,
					event_type: "event",
					...params,
				})
				return response
			},
		}),
	}

	return (
		<div>
			<div className={styles.event_hero_section}>
				<div className={styles.event_hero_img}>
					<Container>
						<form
							className={styles.search_form}
							onSubmit={(e) => {
								e.preventDefault()
								const values = new FormData(e.target)
								let searchKey = values.get("search")
								setSearchState({ ...searchState, keyword: searchKey })
							}}>
							<div className={styles.searchInput}>
								<input name='search' className={styles.input} placeholder='Search event here' />
								<button className={styles.searchButton}>
									<i className='fa fa-search'></i>
								</button>
							</div>

							<div className={classNames(styles.categoryList, styles.search_category_filter)}>
								<DateRangeDropdown value={dateRangeSelection} onChange={setDateRangeSelection} />
								<TimeDropdown value={timeRangeSelection} onChange={setTimeRangeSelection} />
								<span
									style={{
										height: "25px",
										width: "1px",
										borderLeft: "1px solid #ccc",
										alignSelf: "center",
									}}></span>

								<div
									className={styles.categoryList}
									style={{
										display: "inline-flex",
										flexWrap: "nowrap",
										overflowX: "auto",
										msScrollRails: "none",
										"-ms-overflow-style": "none" /* Internet Explorer 10+ */,
										"scrollbar-width": "none" /* Firefox */,
									}}>
									{queries.categories?.data?.map((category) => {
										return (
											<Chip
												key={category.category_id}
												label={category.category_title}
												variant={"outlined"}
												className={styles.categoryChip}
												clickable
												onClick={() => {
													const selected = new Set(categories)
													if (selected.has(category.category_id)) {
														selected.delete(category.category_id)
													} else {
														selected.add(category.category_id)
													}
													setCategories(selected)
												}}
											/>
										)
									})}
								</div>
							</div>

							{hasSearchFilter && (
								<div className={`${styles.categoryList} mt-5`}>
									{searchState.keyword && (
										<Chip
											style={{ backgroundColor: "#f0f0f0" }}
											label={'Search By: "' + searchState.keyword + '"'}
											variant={"outlined"}
											className={styles.categoryChip}
											clickable
											onDelete={() => {
												setSearchState({ ...searchState, keyword: null })
											}}
										/>
									)}
									{categories.size > 0 &&
										Array.from(categories).map((category) => {
											const categoryObj = queries.categories.data.find(
												(c) => c.category_id === category,
											)
											if (!categoryObj) return null
											return (
												<Chip
													key={category}
													style={{ backgroundColor: "#f0f0f0" }}
													label={"Filtered By: " + categoryObj.category_title}
													variant={"outlined"}
													className={styles.categoryChip}
													clickable
													onDelete={() => {
														const selected = new Set(categories)
														selected.delete(category)
														setCategories(selected)
													}}
												/>
											)
										})}

									{dateRangeSelection.length > 0 && (
										<Chip
											style={{ backgroundColor: "#f0f0f0" }}
											label={"Filtered By: " + dateRangeSelection.join(", ")}
											variant={"outlined"}
											className={styles.categoryChip}
											clickable
											onDelete={() => {
												setDateRangeSelection([])
											}}
										/>
									)}

									{timeRangeSelection.length > 0 && (
										<Chip
											style={{ backgroundColor: "#f0f0f0" }}
											label={"Filtered By: " + timeRangeSelection.join(", ")}
											variant={"outlined"}
											className={styles.categoryChip}
											clickable
											onDelete={() => {
												setTimeRangeSelection([])
											}}
										/>
									)}
								</div>
							)}
						</form>
						<div></div>
					</Container>

					{hasSearchFilter ? (
						<Container className={`mt-4 mb-4 pb-4 ${styles.list_container}`}>
							<h2>Search Result:</h2>
							{!queries.search?.data?.data?.length && queries.search.isSuccess ? (
								<div className='mt-5'>No result found</div>
							) : null}
							<EventSmallCardListing data={queries.search?.data?.data} query={queries.search} />
							{queries.search.isLoading && (
								<Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: require("./loader-animation.json"),
										rendererSettings: {
											preserveAspectRatio: "xMidYMid slice",
										},
									}}
									height={300}
									width={300}
								/>
							)}
							{queries.search.isSuccess && (
								<TablePagination
									className='mt-4'
									component='div'
									count={queries.search?.data?.recordsFiltered}
									page={page}
									rowsPerPage={8}
									rowsPerPageOptions={[8]}
									onPageChange={(e, page) => {
										setPage(page)
									}}
								/>
							)}
						</Container>
					) : null}

					<Container>
						<>
							<h2 className='mt-4' style={{ fontSize: "2rem" }}>
								Events
							</h2>
							<div className='px-4'>
								<h3 className='fs24 my-4'>You Won't Want To Miss These</h3>
								<Slider {...settings} className={styles.slider}>
									{queries.events?.data?.featured_events?.map((event, index) => {
										const eventDateFromStr = moment(event.event_from).format("YYYY-MM-DD, ddd")
										const evenDateToStr = moment(event.event_to).format("YYYY-MM-DD, ddd")
										const eventTimeFromStr = event.event_time_from
											? new moment(event.event_time_from, "HH:mm").format("hh:mm A")
											: null
										const eventTimeToStr = event.event_time_to
											? new moment(event.event_time_to, "HH:mm").format("hh:mm A")
											: null

										// handle different event type
										const isRegisterable =
											event.event_registration_from && event.event_registration_to

										const hasEventEnded = moment().isAfter(event.event_to)
										const hasRegisterEnded = moment().isAfter(event.event_registration_end)

										return (
											<div key={index}>
												<div className={`${styles.featured_event_card}`}>
													<div className={classNames(styles.featured_event_info)}>
														<h3 className={styles.title}>{event.event_title}</h3>
														<p className={styles.description}>{event.event_description}</p>
														<div>
															{event.categories &&
																event.categories.length > 0 &&
																event.categories.map((category) => {
																	return (
																		<Badge
																			key={category.category_id}
																			className='mr-1'
																			style={{
																				borderRadius: "5px",
																				background: category.category_color,
																				color: "white",
																			}}>
																			{category.category_title}
																		</Badge>
																	)
																})}
														</div>
														<span
															style={{
																display: "inline-block",
																borderTop: "1px solid #ccc",
																height: "2px",
																marginTop: 20,
																width: "100%",
															}}
														/>
														<div
															style={{
																flexGrow: 1,
																marginTop: 20,
															}}>
															<div
																style={{
																	display: "flex",
																	flexGrow: 1,
																	gap: 20,
																	marginTop: 20,
																}}>
																<div>
																	<i className='fa fa-calendar mr-2 text-primary'></i>
																	{eventDateFromStr == evenDateToStr ? (
																		<span>{eventDateFromStr}</span>
																	) : (
																		<span>
																			{eventDateFromStr} - {evenDateToStr}{" "}
																		</span>
																	)}
																</div>
																{event.event_time_from && (
																	<div>
																		<i className='fa fa-clock mr-2 text-primary'></i>
																		{eventTimeFromStr == eventTimeToStr ? (
																			<span>{eventTimeFromStr}</span>
																		) : (
																			<span>
																				{eventTimeFromStr} - {eventTimeToStr}
																			</span>
																		)}
																	</div>
																)}
															</div>
															<div className='mt-2'>
																{hasEventEnded && (
																	<Badge className={styles.badge_black}>This event has ended</Badge>
																)}
															</div>
														</div>

														<Link
															to={"/events/" + event.event_id}
															state={{ event: event }}
															className='mt-4'>
															<Button
																className='btn-gradient mt-5'
																// onClick={() => setIsRegisterModalOpen(true)} disabled={isRegisterLoading}
															>
																{isRegisterable
																	? hasEventEnded || hasRegisterEnded
																		? "View Event"
																		: "Register Now"
																	: "View Event"}
															</Button>
														</Link>
													</div>

													<div className={styles.featured_event_img}>
														<img src={event.event_listing_image_url} />
													</div>
												</div>
											</div>
										)
									})}
								</Slider>
							</div>
						</>
					</Container>
				</div>
			</div>

			<div className={styles.event_past_event_section}>
				<div className={styles.event_past_event_section_background}>
					{queries.events?.data?.thisquarter_events?.length && (
						<div className={styles.event_past_event_section_container}>
							<Container className={styles.list_container}>
								<h2>What's Happening This Quarter</h2>
								<EventSmallCardListing
									data={queries.events?.data?.thisquarter_events}
									query={queries.events}
								/>
							</Container>
						</div>
					)}

					<Container className={`mt-8 mb-8 pb-9 ${styles.list_container}`}>
						<h2>Past Experience</h2>
						<EventSmallCardListing
							data={queries.events?.data?.past_events}
							query={queries.events}
						/>
					</Container>
				</div>
			</div>
		</div>
	)
}

const EventSmallCardListing = ({ data, query }) => {
	const navigate = useNavigate()
	let eventData = data
	return (
		<div className={styles.event_small_card_list}>
			{eventData?.map((event, index) => {
				const eventDateFromStr = moment(event.event_from).format("YYYY-MM-DD, ddd")
				const evenDateToStr = moment(event.event_to).format("YYYY-MM-DD, ddd")

				const eventTimeFromStr = event.event_time_from
					? new moment(event.event_time_from, "HH:mm").format("hh:mm A")
					: null

				const eventTimeToStr = event.event_time_to
					? new moment(event.event_time_to, "HH:mm").format("hh:mm A")
					: null

				const hasEventEnded = moment().isAfter(event.event_to)

				return (
					<Link
						key={event.event_id}
						to={"/events/" + event.event_id}
						state={{ event: event }}
						style={{ color: "inherit", textDecoration: "inherit", cursor: "pointer" }}>
						<div className={styles.event_small_card}>
							<div
								onClick={(e) => {
									e.preventDefault()
									navigate(`/events/${event.event_id}`, { state: { event: event } })
								}}>
								<img
									style={{
										filter: hasEventEnded ? "brightness(0.5)" : "none",
									}}
									src={event.event_listing_image_url || event.event_image_url || EarthPlacdholder}
								/>
								{hasEventEnded && (
									<Badge className={styles.event_ended_badge}>This event has ended</Badge>
								)}
							</div>
							<div className='px-3 py-3'>
								<h3 className={styles.event_title}>{event.event_title}</h3>

								<p className='mb-0 fs14'>
									<i className='fa fa-calendar mr-2 text-primary'></i>
									{eventDateFromStr == evenDateToStr ? (
										<span>{eventDateFromStr}</span>
									) : (
										<span>
											{eventDateFromStr} - {evenDateToStr}
										</span>
									)}
								</p>

								{eventTimeFromStr && (
									<p className='mb-0 fs14'>
										<i className='fa fa-clock mr-2 text-primary'></i>
										{eventTimeFromStr == eventTimeToStr ? (
											<span>{eventTimeFromStr}</span>
										) : (
											<span>
												{eventTimeFromStr} - {eventTimeToStr}
											</span>
										)}
									</p>
								)}

								{event.categories &&
									event.categories.length > 0 &&
									event.categories.map((category) => {
										return (
											<Badge
												className='ml-0 mr-2'
												key={category.category_id}
												style={{
													borderRadius: "5px",
													background: category.category_color || "#6271DD",
													color: "white",
												}}>
												{category.category_title}
											</Badge>
										)
									})}
							</div>
						</div>
					</Link>
				)
			})}
		</div>
	)
}

const DateRangeDropdown = ({ onChange, value = [] }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	console.log(value)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined

	const handleChange = (event) => {
		// setState({ ...state, [event.target.name]: event.target.checked });
		const selected = new Set(value)
		if (event.currentTarget.checked) {
			selected.add(event.currentTarget.name)
			onChange([...selected])
		} else {
			selected.delete(event.currentTarget.name)
			onChange([...selected])
		}
		console.log("selected", selected)
	}

	const options = [
		{ label: "This Week", value: "thisweek" },
		{ label: "This Month", value: "thismonth" },
		{ label: "This Quarter", value: "thisquarter" },
		{ label: "This Year", value: "thisyear" },
	]

	return (
		<div>
			<Chip
				aria-describedby={id}
				color='primary'
				onClick={handleClick}
				label={
					<>
						<span>Date Range </span> <i className='fa fa-chevron-down'></i>{" "}
					</>
				}
				variant='outlined'
				className={styles.categoryChip}
				clickable
			/>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}>
				<Paper>
					<div className='d-flex flex-column px-1 pr-4 py-2'>
						{options.map((option) => {
							return (
								<FormControlLabel
									key={option.label}
									onChange={handleChange}
									className={`m-0 ${styles.search_checkbox}`}
									control={
										<BlueCheckbox name={option.value} checked={value.includes(option.value)} />
									}
									label={option.label}
								/>
							)
						})}
					</div>
				</Paper>
			</Popover>
		</div>
	)
}

const TimeDropdown = ({ onChange, value = [] }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined
	const handleChange = (event) => {
		// setState({ ...state, [event.target.name]: event.target.checked });
		const selected = new Set(value)
		if (event.currentTarget.checked) {
			selected.add(event.currentTarget.name)
			onChange([...selected])
		} else {
			selected.delete(event.currentTarget.name)
			onChange([...selected])
		}
		console.log("selected", selected)
	}

	const options = [
		{ label: "Morning (Before 12pm)", value: "morning" },
		{ label: "Afternoon (After 12pm)", value: "afternoon" },
		{ label: "Evening (After 5pm)", value: "evening" },
	]
	return (
		<div>
			<Chip
				aria-describedby={id}
				color='primary'
				onClick={handleClick}
				label={
					<>
						<span>Time Range </span> <i className='fa fa-chevron-down'></i>{" "}
					</>
				}
				variant='outlined'
				className={styles.categoryChip}
				clickable
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}>
				<Paper>
					<div className='d-flex flex-column px-1 pr-4 py-2'>
						{options.map((option) => {
							return (
								<FormControlLabel
									key={option.label}
									onChange={handleChange}
									className={`m-0 ${styles.search_checkbox}`}
									control={
										<BlueCheckbox name={option.value} checked={value.includes(option.value)} />
									}
									label={option.label}
								/>
							)
						})}
					</div>
				</Paper>
			</Popover>
		</div>
	)
}

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	autoplay: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	touchMove: false,
	draggable: false,
}

// export default Dashboard;
export default React.memo(Dashboard)
